import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import farcasterLogo from "../images/teleLogo.png";
import lensLogo from "../images/ton_symbol.png";

const whiteLogos = [
  farcasterLogo,
  lensLogo,
];

const logoNames = [
  "Telegram",
  "TON"
]

const darkLogos = [
  farcasterLogo,
  lensLogo,
];

const logoStyle = {
  // width: '100px',
  height: '80px',
  margin: '0 32px',
  // opacity: 1,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 10 }} data-aos="fade-up">
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
        sx={{mb: "30px"}}
      >
        Powered by
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.9 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <Box sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center'}}>
              <img
                src={logo}
                alt={`Fake company number ${index + 1}`}
                style={logoStyle}
              />
              <Typography
                variant="h4"
                align="center"
                color="text.secondary"
              >
                {logoNames[index]}
              </Typography>
            </Box>

          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
