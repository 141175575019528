import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/material/Link";
import visionImg from "../images/vision.png";

const items = [
  {
    icon: <PeopleIcon />,
    title: 'EigenLayer AVS - Guaranteed safety',
    description:
      'Soda Layer is designed based on Eigenlayer’s latest EIGEN Staking AVS, which brings unparalleled security to Soda Layer while reducing the trust bootstrapping cost at the beginning of the protocol.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'EVM Compatibility - Liberating Farcaster Social Data Interoperability',
    description:
      'Soda Layer provides developers an EVM-compatible execution environment, allowing them to easily interact with Farcaster social data in smart contracts. Before the Soda Layer, this data was maintained by off-chain Hubs, greatly unleashing the interoperability potential of Farcaster social data.',
  },
  {
    icon: <CardGiftcardIcon />,
    title: 'Traceable Historical data – Farcaster’s Social Data availability',
    description:
      'In SodaLayer, Farcaster social data is managed through a chain data structure, making the data traceable. Combined with the security brought by EigenLayer AVS, the availability of this data is guaranteed. This is not possible in the original Hubs architecture. Therefore, Soda Layer can become Farcaster\'s trusted DA layer.',
  },
  {
    icon: <CardGiftcardIcon />,
    title: 'Token economic model - improving the decentralization of Farcaster Hubs',
    description:
      'We know that operating a Farcaster Hub does not bring any benefits so far, which may reduce its decentralization. Soda Layer designs a token economic model with dynamic release and flywheel effect, so that the EIGEN Staking Operator who honestly maintains AVS can receive rewards in proportion, thus improving the degree of decentralization.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Cross Chain',
  //   description:
  //     '',
  // },
];

export default function Vision() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
      }}
      data-aos="fade-up"
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '100%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Our Vision
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Tele Hunter provides multiple sustainable Creator Economy tools to Telegram Creators  concerning organic user growth, retention and conversion. With the aid of a series of easy-to-use operation and monetization tools, creators/influencers in the Telegram ecosystem can better manage Member-only content and engagement operations, yielding a higher income.
          </Typography>
        </Box>
        <Box>
          <img src={visionImg}></img>
        </Box>
      </Container>
    </Box>
  );
}
