import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import exchangeImage from "../images/exchange.png";
import arch from "../images/arch.png";

import { visuallyHidden } from '@mui/utils';
import ReactFloaterJs from "react-floaterjs";
import bg from "../images/bg.png";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        height: '100vh',
        // backgroundImage:
        //   theme.palette.mode === 'light'
        //     ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
        //     : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        // backgroundImage: `url(${bg})`,
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
      })}
    >
      <body>
        <div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </body>
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          pt: { xs: 5, sm: 10 },
          pb: { xs: 8, sm: 12 },
          height: '100%'
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '100%' }, textAlign: "center" }}
        >
          <Typography
            variant="h2"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              pb: '50px'
            }}
          >
            {/*The&nbsp;first&nbsp;web3&nbsp;social&nbsp;payment&nbsp;platform*/}
            One-stop Monetization and Marketing Toolset for Sustainable Creator Economy surrounding Telegram Community

            {/*<Typography*/}
            {/*  component="span"*/}
            {/*  variant="h1"*/}
            {/*  sx={{*/}
            {/*    fontSize: 'inherit',*/}
            {/*    color: (theme) =>*/}
            {/*      theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  products*/}
            {/*</Typography>*/}
          </Typography>
          {/*<Typography*/}
          {/*  textAlign="center"*/}
          {/*  color="text.secondary"*/}
          {/*  variant="h5"*/}
          {/*  sx={{ width: { sm: '100%', md: '80%' } }}*/}
          {/*>*/}
          {/*  Tele Hunter is dedicated to providing Sustainable Creator Economy Tools  to Telegram Creators  concerning organic user growth, retention and conversion.Tele Hunter provides a series of easy-to-use operation and monetization tools for creators/influencers in the Telegram ecosystem to manage Member-only content and engagement operations.*/}
          {/*</Typography>*/}
          {/*<Typography variant="caption" textAlign="left">*/}
          {/*  By clicking &quot;Start now&quot; you agree to our&nbsp;*/}
          {/*  <Link href="#" color="primary">*/}
          {/*    Terms & Conditions*/}
          {/*  </Link>*/}
          {/*  .*/}
          {/*</Typography>*/}
        </Stack>

        {/*<Box*/}
        {/*  id="image"*/}
        {/*  sx={(theme) => ({*/}
        {/*    mt: { xs: 8, sm: 10 },*/}
        {/*    alignSelf: 'center',*/}
        {/*    height: { xs: 200, sm: 700 },*/}
        {/*    width: '100%',*/}
        {/*    backgroundImage:*/}
        {/*      theme.palette.mode === 'light'*/}
        {/*        ? 'url("/static/images/templates/templates-images/hero-light.png")'*/}
        {/*        : 'url("/static/images/templates/templates-images/hero-dark.png")',*/}
        {/*    backgroundSize: 'cover',*/}
        {/*    borderRadius: '12px',*/}
        {/*    outline: '1px solid',*/}
        {/*    outlineColor:*/}
        {/*      theme.palette.mode === 'light'*/}
        {/*        ? 'hsla(220, 25%, 80%, 0.5)'*/}
        {/*        : 'hsla(210, 100%, 80%, 0.1)',*/}
        {/*    boxShadow:*/}
        {/*      theme.palette.mode === 'light'*/}
        {/*        ? '0 0 12px 8px hsla(220, 25%, 80%, 0.2)'*/}
        {/*        : '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',*/}
        {/*  })}*/}
        {/*/>*/}
      </Container>
    </Box>
  );
}
