import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/material/Link";
import moneImg from "../images/marketing.png";
import loyaltyImg from "../images/rating.png";
import web3Img from "../images/web3.png";

const items = [
  {
    icon: moneImg,
    title: 'Multiple Creator Monetization Paths powered by Ton Blockchain beyond Ad-based ecosystem',
    description:
      'On the premise of ensuring the basic advertising income, Tele Hunter can enable Creators earn more income with a rich product matrix, such as paid-subscription, and paid access to core premium content using $Ton, $NotCoin and many other Jettons.',
  },
  {
    icon: loyaltyImg,
    title: 'A rich collection of Creator Economy Toolset based on AARRR',
    description:
      'Make it super easy to monetize for creators on Telegram using Ton Blockchain with Followers spontaneously as promoters. With the aid of monetization tools, creators/influencers in the Telegram ecosystem can better manage Member-only content and engagement operations, yielding a higher income.',
  },
  {
    icon: web3Img,
    title: 'Combination of Web3 Innovative Tokenomics and Business Model',
    description:
      'Tele Hunter develops plenty of web3 native configurable tokenomics models for creators to take advantage of Web3 innovative growth strategies, helping them build more sustainable and cohesive communities, e.g subscribe-mining as a service, SBT loytal Program.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Cross Chain',
  //   description:
  //     '',
  // },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
      }}
      data-aos="fade-up"
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '100%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Core Products
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Tele Hunter will focus on three directions to provide a series of monetization and marketing tools for for Sustainable Creator Economy with Telegram Community .
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Typography fontWeight="medium" variant="h5" gutterBottom>
                    {item.title}
                  </Typography>
                  <Box sx={{ m: '50px' }}>
                    <img src={item.icon} width="180"/>
                  </Box>

                  <Typography variant="body1" sx={{ color: 'grey.300' }}>
                    {item.description}
                  </Typography>
                  {/*<Link*/}
                  {/*  color="primary"*/}
                  {/*  variant="body2"*/}
                  {/*  fontWeight="bold"*/}
                  {/*  sx={{*/}
                  {/*    display: 'inline-flex',*/}
                  {/*    alignItems: 'center',*/}
                  {/*    pt: 2,*/}
                  {/*    '& > svg': { transition: '0.2s' },*/}
                  {/*    '&:hover > svg': { transform: 'translateX(2px)' },*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <span>Learn more</span>*/}
                  {/*  <ChevronRightRoundedIcon*/}
                  {/*    fontSize="small"*/}
                  {/*    sx={{ mt: '1px', ml: '2px' }}*/}
                  {/*  />*/}
                  {/*</Link>*/}
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
