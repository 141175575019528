import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import architectureImage from "../images/memo.png";

const logoStyle = {
  // width: '100px',
  margin: '0 32px',
  // opacity: 1,
};

export default function Monetization() {

  return (
    <Box id="logoCollection" sx={{ py: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}} data-aos="fade-up">
      <Typography component="h2" variant="h4" color="text.primary">
        Multiple Creator Monetization Paths powered by Ton Blockchain beyond Ad-based ecosystem
      </Typography>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
        sx={{width: '60%', m: '30px'}}
      >
        On the premise of ensuring the basic advertising income, Tele Hunter can enable Creators earn more income with a rich product matrix, such as paid-subscription, and paid access to core premium content using $Ton, $NotCoin and many other Jettons. For example, start a gated chat and publish secret content that is only visible to paid subscribers using $Ton or other Jettons. Creators can announce the latest memecoin, comment on transactions on your linked wallet, and even publish subscription closed suggestions to share high-quality content and earn extra and sustainable income.
      </Typography>
      <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <img
          src={architectureImage}
          alt="architecture"
          style={logoStyle}
          width="1000"

        />

      </Box>
    </Box>
  );
}
