import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import architectureImage from "../images/invertedPyramid.png";

const logoStyle = {
  // width: '100px',
  margin: '0 32px',
  // opacity: 1,
};

export default function Architecture() {

  return (
    <Box id="logoCollection" sx={{ py: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}} data-aos="fade-up">
      <Typography component="h2" variant="h4" color="text.primary">
        Multi-Level Affiliate Marketing
      </Typography>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
        sx={{width: '60%', m: '30px'}}
      >
        Tele Hunter's original two-level inverted pyramid referral program establishes a commission distribution with positive feedback effects. In Tele Hunter , each user is both a paid member as well as an sale agent of the paid community. Apart from connecting with favorite creators, one can also earn commissions by sharing and referring more people to join the same paid community. Through such a referral and commission distribution program, excellent communities will be heavily exposed and quickly quickly discovered everywhere on social media. It’s a win-win cooperation and vibrant community can be effectively established.
      </Typography>
      <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <img
          src={architectureImage}
          alt="architecture"
          style={logoStyle}
          width="1100"

        />

      </Box>
    </Box>
  );
}
