import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/material/Link";
import architectureImage from "../images/saas.png";

const items = [
  {
    icon: <PeopleIcon />,
    title: 'Decentralized',
    description:
      'The logic layer and fund settlement layer of all HelloPay functional modules are implemented through smart contracts on the chain, ensuring the openness and transparency of the entire process and increasing credibility.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Social payment fitness',
    description:
      'HelloPay will focus on social payment scenarios, fully explore user needs, and launch more interesting social payment functions.',
  },
  {
    icon: <CardGiftcardIcon />,
    title: 'Totally free to use',
    description:
      'Using HelloPay’s functionality is completely free, you only need to pay the Gas fee for sending transactions on the blockchain.',
  },
  // {
  //   icon: <AutoFixHighRoundedIcon />,
  //   title: 'Cross Chain',
  //   description:
  //     '',
  // },
];
const logoStyle = {
  // width: '100px',
  margin: '0 32px',
  // opacity: 1,
};
export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'hsl(220, 30%, 2%)',
      }}
      data-aos="fade-up"
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '100%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Subscribe Mining as a service
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400', m: '30px' }}>
            Creators can easily create a subscription mining service for their paid communities members, and allow creators to customize parameter configurations according to their own circumstances,  establishing a sustainable creator economic model. The following is a typical subscription mining process.
          </Typography>
        </Box>
        <Box sx={{ mt: 6, opacity: 0.9, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <img
            src={architectureImage}
            alt="architecture"
            style={logoStyle}
            width="1100"

          />

        </Box>
      </Container>
    </Box>
  );
}
