import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
      data-aos="fade-up"
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What is Tele-Hunter?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Tele Hunter is committed to become a One-stop Monetization and Marketing Toolset for Sustainable Creator Economy with Telegram Community Powered by Ton Blockchain.

              Tele Hunter provides multiple sustainable Creator Economy tools  to Telegram Creators  concerning organic user growth, retention and conversion. With the aid of a series of easy-to-use operation and monetization tools, creators/influencers in the Telegram ecosystem can better manage Member-only content and engagement operations, yielding a higher income.



              {/*<Link> support@email.com </Link>*/}
              {/*or calling our toll-free number. We&apos;re here to assist you*/}
              {/*promptly.*/}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Why should users use Tele Hunter and How can they earn using it ?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              As a creator, you can create paid channels/group chat and earn more income in the form of Jettons or Cryptocurrency. Besides, one can expand Personal Influence and Earning Ability using the Multiple Creator Economy Toolset. Moreover, creators can take advantage of Web3 native Tokenomics and Business Model to further boost income.

              As a follower or user, one can gain by finding channels and group chats of high-quality creators, pay to join groups to obtain high-quality content and information, and communicate closely and efficiently with excellent creators. Or, one can promote for favorite creators to earn commissions through exclusive invitation links in Multi level Rebate Referral System. Moreover, one can claim $Hunter tokens and stake them to channels or groups of potential creators to obtain additional income.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Which Blockchain is Tele Hunter Based??
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Tele Hunter is based on Ton Blockchain and enables Creators to earn more income with a rich product matrix, such as paid-subscription, and paid access to core premium content using $Ton, $NotCoin and many other Jettons.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is using Tele-Hunter safe?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Absolutely, the core logic of Tele-Hunter is implemented through smart contracts on TON blockchain, ensuring openness and transparency of execution and enhancing credibility.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
